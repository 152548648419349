import Logo from "../assets/artemec.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyIcon from "@mui/icons-material/Key";

import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const defaultValue = {
  email: "",
  password: "",
  showPassword: false,
};
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const vertical = "top";
const horizontal = "center";

const Login = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const openSnackBar = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleClickShowPassword = (e) => {
    setData({ ...data, showPassword: !data.showPassword });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(data.email, data.password);
    if (!data.email || !data.password) return openSnackBar();
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
      setLoading(false);
      navigate("/");
    } catch (e) {
      // console.log(e);
      openSnackBar();
    }
    setLoading(false);
  };
  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <div className="grid place-items-center min-h-screen login">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Invalid Email or Password
        </Alert>
      </Snackbar>
      <div>
        <div className="flex justify-center">
          <img className="h-[100px]" src={Logo} alt="artemec logo" />
        </div>
        <form
          className="flex flex-col space-y-5 bg-[#ececec] p-12 rounded-lg max-w-[90vw] mx-auto w-[500px]"
          onSubmit={onSubmit}
        >
          <span className="text-3xl font-bold mb-5">Sign in</span>
          <FormControl fullWidth className="my-form-control">
            <TextField
              variant="standard"
              size="small"
              type="email"
              name="email"
              placeholder="Email"
              value={data.email}
              onChange={onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl fullWidth className="my-form-control">
            <TextField
              variant="standard"
              size="small"
              type={data.showPassword ? "text" : "password"}
              value={data.password}
              name="password"
              onChange={onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {data.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="Password"
            />
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            style={{ textTransform: "none" }}
            disabled={loading}
          >
            Sign in
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
// test@mail.com 123456
