import "./App.css";
import Login from "./pages/Login";
import Home from "./pages/Home";
import { useEffect } from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import Print from "./pages/Print";
import View from "./pages/View";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, setUser } from "./features/user/userSlice";

function App() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      dispatch(setUser(user?.toJSON()));
      if (!user) {
        navigate("/login");
      }
    });
  }, [dispatch, navigate]);
  return (
    <Routes>
      <Route exact path="/" element={user && <Home />} />
      <Route
        exact
        path="/login"
        element={!user ? <Login /> : <Navigate to="/" replace />}
      />

      <Route exact path="/print" element={user && <Print />} />
      <Route exact path="/view" element={user && <View />} />
      {/* <Route exact path="/edit/:id" element={user && <Home edit={true} />} />
      <Route exact path="/view/:id" element={user && <Print view={true} />} />
      <Route exact path="/print" element={user && <Print edit={true} />} /> */}
    </Routes>
  );
}

export default App;
