import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { every, finalTotal } from "../utils/calc";

import { useSelector, useDispatch } from "react-redux";
import { selectFormData, setFormData } from "../features/form/formSlice";

const Result = () => {
  const formData = useSelector(selectFormData);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const onChange = (e) => {
    dispatch(
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      })
    );
  };

  const discountChange = (e) => {
    const discount = parseInt(e.target.value);
    if (
      (!isNaN(discount) && discount >= 0 && discount < 18) ||
      discount === 23 ||
      discount === 32
    ) {
      const { subTotal, gstAmount, total, originalSubTotal, addOnTotal } =
        finalTotal(formData.componentList, formData.gstPer, discount);

      dispatch(
        setFormData({
          ...formData,
          discount,
          subTotal,
          gstAmount,
          addOnTotal,
          total,
          originalSubTotal,
        })
      );
    }
  };

  const validate = (e) => {
    const [error, field] = every({
      "Nalco Rate": formData.nalcoRate,
      Component: formData.component,
      Quantity: formData.quantity,
    });

    if (error.length) return alert(`${field} ${error}`);

    if (formData.componentList.length === 0)
      return alert("Add atleast one component");

    navigate("/print");
  };

  return (
    <div className="flex my-5 space-y-2 mb-[60px] mx-5 ">
      <div className="flex justify-between w-full flex-wrap space-y-4">
        <div className="flex flex-col justify-start">
          <p className="underline underline-offset-[3px] mb-2">NOTE :</p>
          <textarea
            className="input-field col-span-2"
            type="text"
            name="note"
            rows={8}
            cols={40}
            style={{ resize: "none", whiteSpace: "pre-line" }}
            placeholder="Note"
            value={formData.note}
            onChange={onChange}
          />
        </div>
        <div>
          <hr className="col-span-3 pt-2" />
          <div className="grid grid-cols-3 gap-2 ">
            <span className="left-side justify-self-start place-self-center">
              Discount<span className="text-xs"> (%)</span>
            </span>
            <input
              className="input-field col-span-2"
              type="number"
              name="discount"
              value={formData.discount}
              onChange={discountChange}
            />
            <span className="left-side justify-self-start place-self-center">
              Sub. Total
            </span>
            <input
              className="input-field col-span-2"
              type="number"
              name="subTotal"
              readOnly
              value={formData.subTotal}
              onChange={onChange}
            />
            <span className="left-side justify-self-start place-self-center">
              Add-On's
            </span>
            <input
              className="input-field col-span-2"
              type="number"
              name="addOnTotal"
              readOnly
              value={formData.addOnTotal}
              onChange={onChange}
            />
            <span className="left-side justify-self-start place-self-center">
              GST<span className="text-xs"> (18%)</span>
            </span>
            {/* <div className="col-span-2 flex"> */}
            {/* <FormControl className="my-form-control" sx={{ width: 90 }}>
              <TextField
                variant="standard"
                size="small"
                name="gstPer"
                value={formData.gstPer}
                onChange={onChange}
                // Discount direct sub-total se subtract karna hai na ?
                // Sub-Total se
                //  ? Yes
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                inputProps={{ readOnly: true }}
              />
            </FormControl> */}
            <input
              className="input-field col-span-2"
              type="number"
              name="gstAmount"
              readOnly
              placeholder="Rs."
              value={formData.gstAmount}
              onChange={onChange}
            />
            <hr className="col-span-3 mt-2 mb-2" />
            <span className="left-side justify-self-start place-self-center text-xl font-medium">
              Total
            </span>
            <input
              className="input-field col-span-2"
              type="number"
              name="total"
              readOnly
              placeholder="Rs."
              value={formData.total}
              onChange={onChange}
            />
          </div>
          <div className="pt-3 flex justify-end">
            <Button
              variant="outlined"
              onClick={validate}
              endIcon={<ArrowRightAltIcon />}
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
