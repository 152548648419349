export const staticColumns = [
  { field: "id", headerName: "ID", width: 200, hide: true },
  {
    field: "qNo",
    headerName: "Q. No.",
    width: 180,
  },
  {
    field: "date",
    headerName: "Date",
    width: 110,
    valueGetter: (params) => {
      return `${params.row.date.toDate().toDateString()}`;
    },
  },
  {
    field: "client",
    headerName: "Client",
    width: 200,
  },
  {
    field: "project",
    headerName: "Project",
    width: 200,
  },
  {
    field: "ref",
    headerName: "Reference",
    width: 100,
    hide: true,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 100,
    hide: true,
  },
  {
    field: "mail",
    headerName: "Mail",
    width: 160,
    hide: true,
  },
  {
    field: "bilAddr",
    headerName: "Billing Address",
    width: 160,
    hide: true,
  },
  {
    field: "delAddr",
    headerName: "Delivery Address",
    width: 80,
    hide: true,
  },
  {
    field: "nalcoRate",
    headerName: "Nalco",
    width: 70,
    hide: true,
  },
  {
    field: "alumgrade",
    headerName: "Aluminum Grade",
    width: 70,
    hide: true,
  },
  {
    field: "alumingot",
    headerName: "Aluminium Ingot",
    width: 70,
    hide: true,
  },
  {
    field: "componentList",
    headerName: "Components",
    width: 500,
    renderCell: (params) => (
      <table>
        <thead>
          <tr>
            <th>component</th>
            <th>Amount</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {params.value.map((component, i) => (
            <tr key={i}>
              <td>{component.components}</td>
              <td>{component.amount}</td>
              <td>{component.quantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ),
    hide: true,
  },
  {
    field: "originalSubTotal",
    headerName: "Total",
    width: 120,
  },
  {
    field: "discount",
    headerName: "Discount (%)",
    width: 120,
  },
  {
    field: "subTotal",
    headerName: "Sub Total",
    width: 120,
  },
  {
    field: "gstAmount",
    headerName: "GST",
    width: 120,
  },
  {
    field: "total",
    headerName: "Net Total",
    width: 120,
  },
  {
    field: "note",
    headerName: "Note",
    width: 100,
    hide: true,
  },
];
