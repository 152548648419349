import React from "react";
import Logo from "../assets/artemec.png";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";


const Nav = () => {
  return (
    <div className="flex justify-between bg-white text-black p-1 border-b-4 border-black mb-5">
      <div>
        <img className="h-16" src={Logo} alt="artemec logo" loading="lazy" />
      </div>
      <div className="flex justify-end items-center">
        <div className="font-bold sm:text-xl mr-2">Stone Cladding Systems</div>
        <button
          className="bg-red-500 px-4 py-2 rounded-md hover:bg-red-500 text-white hover:text-white m-3 mr-4"
          onClick={(e) =>
            signOut(auth)
          }>
          Log Out
        </button>
      </div>
    </div>
  );
};

export default Nav;
