import db, { auth } from "../firebase";
import {
  collection,
  addDoc,
  doc,
  query,
  getDocs,
  updateDoc,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";

// const typeMap = {
//   Quotation: "quotation no",
//   "Proforma Invoice": "proforma_i no",
//   "Tax Invoice": "tax_i no",
// };

// const getCurrentLength = async (type) => {
//   /**
//    * Types: quotation, proforma invoice, tax invoice
//    */

//   const docRef = await getDoc(doc(db, "length", typeMap[type]));
//   return docRef.data().length;
// };

// const getVoucherNumber = async (type) => {
//   const number = await getCurrentLength(type);

//   console.log(number);

//   // Iska corroesponding acromic change kar dena jaisa chahiye
//   if (type === "Quotation") return "AA-" + (number + 1);
//   else if (type === "Proforma Invoice") return "AE-" + (number + 1);
//   else if (type === "Tax Invoice") return "AE-" + (number + 1);
//   else throw new Error("Company not found");
// };

const addQuotation = async (quotation) => {
  try {
    const quotObj = {
      ...quotation,
      user: auth.currentUser.email,
    };
    const docRef = await addDoc(collection(db, "quotations"), quotObj);

    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document, ", e);
    alert("Error adding document: ", e);
  }
};

const updateQuotation = async (quotation) => {
  try {
    const quotObj = {
      ...quotation,
    };

    const docRef = doc(db, "quotations", quotation.id);

    await updateDoc(docRef, quotObj);

    // const docRef = await addDoc(collection(db, "quotations"), quotObj);

    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document, ", e);
    alert("Error adding document: ", e);
  }
};

const getAllQuots = async () => {
  const q = query(collection(db, "quotations"));
  const docs = await getDocs(q);

  return docs.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
};

const deleteQuot = async (id) => {
  if (!id) {
    return false;
  }
  try {
    const docRef = doc(db, "quotations", id);
    await deleteDoc(docRef);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const deleteBulkQuots = async (ids) => {
  if (!ids) throw new Error("invalid ids");
  const batch = writeBatch(db);
  ids.map((id) => batch.delete(doc(db, "quotations", id)));
  await batch.commit();
};

export {
  addQuotation,
  getAllQuots,
  deleteQuot,
  deleteBulkQuots,
  updateQuotation,
};
