import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, IconButton } from "@mui/material";

import { getAllQuots } from "../util/db";

import { useDispatch } from "react-redux";
import {
  setIsEdit,
  setFormData,
  setCompId,
  setIsView,
} from "../features/form/formSlice";
import { staticColumns } from "../util/viewTableColumns";

const View = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getAllQuots().then((docs) => {
      setRows(docs);
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      field: "View",
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="contained"
            color="primary"
            onClick={(e) => onViewClick(cellValues.row)}
            disabled={deleteLoading}
          >
            <VisibilityIcon />
          </IconButton>
        );
      },
      width: 60,
    },
    {
      field: "Print",
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="contained"
            color="primary"
            onClick={(e) => onPrintClick(cellValues.row)}
            disabled={deleteLoading}
          >
            <PrintIcon />
          </IconButton>
        );
      },
      width: 60,
    },
    {
      field: "Edit",
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="contained"
            color="primary"
            onClick={(e) => onEditClick(cellValues.row)}
            disabled={deleteLoading}
          >
            <EditIcon />
          </IconButton>
        );
      },
      width: 60,
    },
    {
      field: "Delete",
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="contained"
            onClick={(e) =>
              onDeleteClick(cellValues.row.voucherNumber, cellValues.row.id)
            }
            color="error"
            disabled={deleteLoading}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
      width: 60,
    },
    ...staticColumns,
  ];
  const onBulkDelete = async (e) => {
    // if (!selectedRowsIds) return alert("No rows selected");
    // console.log(selectedRowsIds);
    // const sanityCheck = confirm(
    //   `Do you want to delete ${selectedRowsIds.length} Vouchers`
    // );
    // console.log(sanityCheck);
    // if (!sanityCheck) return;
    // setdeleteLoading(true);
    // try {
    //   await deleteAll(selectedRowsIds);
    //   setRows(rows.filter((row) => !selectedRowsIds.includes(row.id)));
    // } catch (e) {
    //   alert(e);
    // }
    // setdeleteLoading(false);
  };

  const customToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <Button
          onClick={onBulkDelete}
          color="error"
          disabled={selectedRowsIds.length === 0}
        >
          Bulk Delete
        </Button>
      </GridToolbarContainer>
    );
  };

  const helperSetFormData = (data) => {
    dispatch(
      setFormData({
        ...data,
        date: data.date.toDate().getTime(),
      })
    );

    dispatch(
      setCompId(
        data.componentList.reduce(
          (max, comp) => (comp.id > max ? comp.id : max),
          -Infinity
        ) + 1
      )
    );
  };

  const onPrintClick = (data) => {
    delete data.id;
    helperSetFormData(data);
    navigate("/print");
  };

  const onEditClick = (data) => {
    console.log(data);
    helperSetFormData(data);
    dispatch(setIsEdit(true));

    navigate("/");
  };

  const onViewClick = (data) => {
    helperSetFormData(data);
    dispatch(setIsEdit(true));
    dispatch(setIsView(true));
    navigate("/print");
  };

  const onDeleteClick = async (vrn, id) => {
    // if (!id) return alert("Cannot delete invalid ID");
    // const sanityCheck = confirm(`Do you want to delete Voucher ${vrn}`);
    // console.log(sanityCheck);
    // if (!sanityCheck) return;
    // setdeleteLoading(true);
    // const success = await deleteVoucher(id);
    // if (success) {
    //   alert("Successfully deleted");
    //   setRows(rows.filter((row) => row.id !== id));
    // } else alert("Something went wrong");
    // console.log(id);
    // setdeleteLoading(false);
  };

  return (
    <div className="">
      <nav className="flex items-center justify-between px-5 py-2 bg-[#111] text-white shadow shadow-gray-600 mb-1">
        <div>Voucher</div>
        <button
          className="px-10 py-2 rounded-full text-white bg-blue-500 hover:bg-blue-600 "
          onClick={(e) => navigate("/")}
        >
          Add
        </button>
      </nav>
      <div style={{ height: "100vh", minHeight: "100vh", width: "100%" }}>
        <DataGrid
          components={{ Toolbar: customToolbar }}
          rows={rows}
          columns={columns}
          loading={loading}
          onSelectionModelChange={(selectedRows) =>
            setSelectedRowsIds(selectedRows)
          }
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
    </div>
  );
};

export default View;
