import React from "react";
import Form from "../components/Form";
import CompTable from "../components/CompTable";
import Result from "../components/Result";
import Nav from "../components/Nav";
const Home = ({ edit }) => {
  return (
    <div className="min-h-screen  min-w-screen flex flex-col">
      <Nav />
      <div className="flex-1 flex flex-wrap justify-center">
        <div className="flex flex-col w-4/6 mt-1">
          <Form /> <CompTable className="mt-8 max-w-full" />
          <div className="flex flex-col justify-between">
            <Result />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
