import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {
    qNo: "",
    date: new Date().getTime(),
    client: "",
    project: "",
    ref: "",
    phone: "",
    mail: "",
    bilAddr: "",
    delAddr: "",
    nalcoRate: "",
    alumgrade: "6063 T6",
    alumingot: "Aluminium Ingot : IE07",
    addOnName: "",
    addOnRate: "",
    addOnQuantity: "",
    addOnUnit: "",
    addOnAmount: 0,
    component: "",
    componentUnit: "r.mt",
    quantity: "0",
    note: "Payment terms :-\n100% Advance along with the order.\n\nAUM ARTEMEC LLP\nGSTIN : 27ABTFA0818J1Z6\n\nThe system rates are as per today's NALCO price.\nIt may change at the time of final order.",
    subTotal: "0",
    gstPer: "18",
    gstAmount: "0",
    originalSubTotal: 0,
    total: "0",
    discount: 0,
    componentList: [],
    addOnComponentList: [],
    formType: "Quotation",
  },
  compId: 1,
  isEdit: false,
  isView: false,
};

export const formSlice = createSlice({
  name: "formData",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
    setCompId: (state, action) => {
      state.compId = action.payload;
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    setIsView: (state, action) => {
      state.isView = action.payload;
    },
  },
});

export const { setFormData, setCompId, setIsEdit, setIsView } =
  formSlice.actions;

export const selectFormData = (state) => state.formData.formData;
export const selectCompId = (state) => state.formData.compId;
export const selectIsEdit = (state) => state.formData.isEdit;
export const selectIsView = (state) => state.formData.isView;
export default formSlice.reducer;
