import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCx1tsdLpJLHJbZ8XDV_O4NVy2J27z9SJU",
  authDomain: "aument-systemcalc.firebaseapp.com",
  projectId: "aument-systemcalc",
  storageBucket: "aument-systemcalc.appspot.com",
  messagingSenderId: "1027884609989",
  appId: "1:1027884609989:web:798580355f2a05fcf8dbac",
  measurementId: "G-LMCP20QV5W",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const auth = getAuth();

export default db;

export { auth };
