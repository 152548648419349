import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React from "react";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  calculateAmount,
  calculateAddOnAmount,
  every,
  finalTotal,
} from "../utils/calc";

import { useSelector, useDispatch } from "react-redux";
import {
  selectFormData,
  setFormData,
  selectCompId,
  setCompId,
} from "../features/form/formSlice";

const Form = () => {
  const formData = useSelector(selectFormData);
  const compId = useSelector(selectCompId);
  const dispatch = useDispatch();

  const onChange = (e) => {
    dispatch(
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      })
    );
  };

  const addComponent = (e) => {
    const components = formData.component;
    const componentUnit = formData.componentUnit;
    const quantity = parseFloat(formData.quantity);
    const nalcoRate = parseFloat(formData.nalcoRate);
    const gstPer = parseFloat(formData.gstPer);
    const [error, field] = every({
      nalcoRate,
      components,
      quantity,
      unit: componentUnit,
      "Gst Percent": gstPer,
    });
    if (error.length) return alert(`${field} ${error}`);

    const amount = calculateAmount({
      components,
      quantity,
      nalcoRate,
    });

    const componentList = [
      ...formData.componentList,
      {
        id: compId,
        components,
        quantity,
        componentUnit,
        amount,
        addon: false,
      },
    ];

    const { subTotal, gstAmount, total, originalSubTotal } = finalTotal(
      componentList,
      formData.gstPer,
      parseInt(formData.discount) || 0
    );

    dispatch(
      setFormData({
        ...formData,
        componentList,
        subTotal,
        gstAmount,
        total,
        originalSubTotal,
      })
    );

    dispatch(setCompId(compId + 1));
  };

  const addonComponent = (e) => {
    const components = formData.addOnName;
    const quantity = parseFloat(formData.addOnQuantity);
    const rate = parseFloat(formData.addOnRate);
    const gstPer = parseFloat(formData.gstPer);
    const addOnUnit = formData.addOnUnit;
    const [error, field] = every({
      rate,
      components,
      quantity,
      unit: addOnUnit,
      "Gst Percent": gstPer,
    });

    const amount = calculateAddOnAmount({
      components,
      quantity,
      rate,
    });

    if (error.length) return alert(`${field} ${error}`);

    const componentList = [
      ...formData.componentList,
      {
        id: compId,
        components,
        quantity,
        amount,
        addOnUnit,
        addon: true,
      },
    ];

    const { subTotal, gstAmount, total, originalSubTotal, addOnTotal } =
      finalTotal(
        componentList,
        formData.gstPer,
        parseInt(formData.discount) || 0
      );

    dispatch(
      setFormData({
        ...formData,
        componentList,
        subTotal,
        gstAmount,
        total,
        addOnTotal,
        originalSubTotal,
      })
    );

    dispatch(setCompId(compId + 1));
  };

  return (
    <div style={{ maxWidth: "90vw" }}>
      {/* {console.log(formData)} */}
      <div>
        <FormControl fullWidth>
          <RadioGroup
            row
            aria-labelledby="payment-radio-label"
            name="formType"
            value={formData.formType}
            onChange={onChange}
            className="flex justify-evenly w-full"
          >
            <FormControlLabel
              value="Quotation"
              control={<Radio />}
              label="Quotation"
            />
            <FormControlLabel
              value="Proforma Invoice"
              control={<Radio />}
              label="Proforma Invoice"
            />
            <FormControlLabel
              value="Tax Invoice"
              control={<Radio />}
              label="Tax Invoice"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="grid gap-2 grid-cols-[100px_minmax(0,_1fr)] mb-8 mt-5">
        <span className="justify-self-start place-self-center">Q. No.</span>
        <div className=" flex ">
          <input
            className="input-field"
            type="text"
            name="qNo"
            placeholder="AACS-0-00-000"
            value={formData.qNo}
            onChange={onChange}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              name="Date"
              value={new Date(formData.date)}
              renderInput={(params) => <TextField {...params} size="small" />}
              onChange={(e) =>
                dispatch(
                  setFormData({
                    ...formData,
                    date: e.getTime(),
                  })
                )
              }
            />
          </LocalizationProvider>
        </div>
        <span className="justify-self-start place-self-center">Client</span>
        <input
          className="input-field "
          type="text"
          name="client"
          placeholder="Name"
          value={formData.client}
          onChange={onChange}
        />
        <span className="justify-self-start place-self-center">Project</span>
        <input
          className="input-field "
          type="text"
          name="project"
          placeholder="Name"
          value={formData.project}
          onChange={onChange}
        />
        <span className="pt-1">Reference</span>
        <div className="">
          <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2 gap-2">
            <input
              className="input-field col-span-2"
              type="text"
              name="ref"
              placeholder="Name"
              value={formData.ref}
              onChange={onChange}
            />
            <input
              className="input-field"
              type="tel"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={onChange}
            />
            <input
              className="input-field"
              type="email"
              name="mail"
              placeholder="Mail"
              value={formData.mail}
              onChange={onChange}
            />
          </div>
        </div>
        <span className="pt-1">Address</span>

        <div className="grid grid-flow-row-dense grid-cols-4 grid-rows-1 gap-2">
          <textarea
            className="input-field col-span-2"
            type="text"
            name="bilAddr"
            rows={4}
            style={{ resize: "none" }}
            placeholder="Billing Address"
            value={formData.bilAddr}
            onChange={onChange}
          />
          <textarea
            className="input-field col-span-2"
            type="text"
            name="delAddr"
            rows={4}
            style={{ resize: "none" }}
            placeholder="Delivery Address"
            value={formData.delAddr}
            onChange={onChange}
          />
        </div>

        <span className="justify-self-start place-self-center">NALCO Rate</span>
        <div className="">
          <div className="grid grid-cols-1 grid-rows-3 gap-2 lg:grid-rows-1 lg:grid-cols-5">
            <input
              className="input-field col-span-2"
              type="number"
              name="nalcoRate"
              placeholder="Rs."
              value={formData.nalcoRate}
              onChange={onChange}
            />
            <input
              className="input-field text-center col-span-1"
              type="text"
              name="alumgrade"
              disabled
              value={formData.alumgrade}
              readOnly
            />
            <input
              className="input-field text-center col-span-2"
              type="text"
              name="client_name"
              disabled
              value={formData.alumingot}
              readOnly
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="flex flex-wrap gap-11 items-center mt-8 mb-4">
        <span className="justify-self-start place-self-center">System : </span>
        <FormControl size="small" sx={{ width: 350 }}>
          <InputLabel id="demo-simple-select-label">
            Select Components
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="component"
            label="Select Components"
            value={formData.component}
            onChange={onChange}
          >
            <MenuItem value={"LAIO - Vertical Mullion"}>
              LAIO - Vertical Mullion
            </MenuItem>
            <MenuItem value={"LAIO - End Vertical"}>
              LAIO - End Vertical
            </MenuItem>
            <MenuItem value={"LAIO 4.0 - Vertical Mullion"}>
              LAIO 4.0 - Vertical Mullion
            </MenuItem>
            <MenuItem value={"LAIO - Horizontal Runner"}>
              LAIO - Horizontal Runner
            </MenuItem>
            <MenuItem value={"LAIO - Horizontal Runner - 36"}>
              LAIO - Horizontal Runner
              <span className="text-xs pl-1">(36)</span>
            </MenuItem>
            <MenuItem value={"ARTON - Back Runner"}>
              ARTON - Back Runner
            </MenuItem>
            <MenuItem value={"ARTON - Back Clip"}>ARTON - Back Clip</MenuItem>
            <MenuItem value={"RAINURE - Top Runner"}>
              RAINURE - Top Runner
            </MenuItem>
            <MenuItem value={"RAINURE - Bottom Runner"}>
              RAINURE - Bottom Runner
            </MenuItem>
            <MenuItem value={"LINEAR MOUNT - Horizontal Runner"}>
              LINEAR MOUNT - Horizontal Runner
            </MenuItem>
            <MenuItem value={"LINEAR MOUNT - Horizontal Runner - 30"}>
              LINEAR MOUNT - Horizontal Runner
              <span className="text-xs pl-1">(30)</span>
            </MenuItem>
            <MenuItem value={"SLIMCLAD - Profile"}>
              SLIMCLAD - Profile
            </MenuItem>
            <MenuItem value={"SLIMCLAD - Hover Clip"}>
              SLIMCLAD - Hover Clip
            </MenuItem>
            <MenuItem value={"TILECLAD - Profile"}>
              TILECLAD - Profile
              <span className="text-xs pl-1">(12mm Tile)</span>
            </MenuItem>
            <MenuItem value={"TILECLAD - Notch Clip"}>
              TILECLAD - Notch Clip
              <span className="text-xs pl-1">(12mm Tile)</span>
            </MenuItem>
            <MenuItem value={"BRACKET - 75.50"}>BRACKET - 75.50</MenuItem>
            <MenuItem value={"BRACKET - 100.50"}>BRACKET - 100.50</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <TextField
            className="w-24"
            variant="standard"
            size="small"
            name="quantity"
            value={formData.quantity}
            onChange={onChange}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">r.mt.</InputAdornment>
            //   ),
            // }}
          />
        </FormControl>

        <input
          className="input-field w-24"
          type="text"
          name="componentUnit"
          placeholder="Unit"
          value={formData.componentUnit}
          readOnly
          onChange={onChange}
        />

        <Button className="self-end" variant="outlined" onClick={addComponent}>
          + Add
        </Button>
      </div>
      <div className="grid gap-2 grid-cols-[100px_minmax(0,_1fr)] mb-8 mt-5">
        <span className="justify-self-start place-self-center">Add-On's :</span>
        <div className="">
          <div className="grid grid-cols-1 grid-rows-3 gap-2 lg:grid-rows-1 lg:grid-cols-6">
            <input
              className="input-field col-span-2"
              type="text"
              name="addOnName"
              placeholder="Component Name"
              value={formData.addOnName}
              onChange={onChange}
            />
            <input
              className="input-field col-span-1"
              type="text"
              name="addOnRate"
              placeholder="Rate"
              value={formData.addOnRate}
              onChange={onChange}
            />
            <input
              className="input-field col-span-1"
              type="text"
              name="addOnQuantity"
              placeholder="Quantity"
              value={formData.addOnQuantity}
              onChange={onChange}
            />
            <input
              className="input-field col-span-1"
              type="text"
              name="addOnUnit"
              placeholder="Units"
              value={formData.addOnUnit}
              onChange={onChange}
            />
            <Button
              className="self-end"
              variant="outlined"
              onClick={addonComponent}
            >
              + Add
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
