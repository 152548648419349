// const componentUnit = {
//   "LAIO - Vertical Mullion": 1.97,
//   "LAIO 4.0 - Vertical Mullion": 5.82,
//   "LAIO - Horizontal Runner": 1.47,
//   "ARTON - Back Runner": 1.25,
//   "ARTON - Back Clip": 0.93,
//   "RAINURE - Top Runner": 1.39,
//   "RAINURE - Bottom Runner": 0.8,
//   "LINEAR MOUNT - Horizontal Runner": 1.23,
//   "BRACKET - 75.50": 2.75,
//   "BRACKET - 100.50": 3.5,
// };

const componentWeight = {
  "LAIO - Vertical Mullion": 1.402,
  "LAIO - End Vertical": 0.950,
  "LAIO 4.0 - Vertical Mullion": 3.879,
  "LAIO - Horizontal Runner": 1.023,
  "LAIO - Horizontal Runner - 36": 1.10,
  "ARTON - Back Runner": 0.810,
  "ARTON - Back Clip": 0.768,
  "RAINURE - Top Runner": 0.928,
  "RAINURE - Bottom Runner": 0.536,
  "LINEAR MOUNT - Horizontal Runner": 0.819,
  "LINEAR MOUNT - Horizontal Runner - 30": 1.35,
  "SLIMCLAD - Profile": 0.789,
  "SLIMCLAD - Hover Clip": 0.102,
  "TILECLAD - Profile": 0.464,
  "TILECLAD - Notch Clip": 0.157,
  "BRACKET - 75.50": 1.826,
  "BRACKET - 100.50": 2.336,
};

const normal = (compObject) => {
  return Math.ceil(
    (compObject.nalcoRate + 61) *
      componentWeight[compObject.components] *
      1.75 *
      compObject.quantity
  );
};

const calculateAmount = (compObject) => {
  return normal(compObject);
};

const normalAddOn = (compObject) => {
  return Math.ceil(compObject.quantity * compObject.rate);
};

const calculateAddOnAmount = (compObject) => {
  return normalAddOn(compObject);
};

// const calculateAddOnTotal = (addOnComponentList) => {
//   return Math.ceil(
//     addOnComponentList.reduce((total, current) => total + current.amount, 0)
//   );
// };

const calculateTotal = (componentList) => {
  let subTotal = 0;
  let addOnTotal = 0;

  for (const component of componentList) {
    if (component.addon) {
      addOnTotal += component.amount;
    }
    else {
      subTotal += component.amount;
    }
  }

  return {subTotal, addOnTotal};

}

export const calculatePercent = (total, per) => Math.ceil((total * per) / 100);

const finalTotal = (componentList, gst, discount = 0, addOnComponentList = []) => {
  const {subTotal : total, addOnTotal} = calculateTotal(componentList);
  const originalSubTotal = total;

  const discountSubTotal = calculatePercent(total, discount);
  const subTotal = total - discountSubTotal;
  
  const gstAmount = calculatePercent(subTotal + addOnTotal, gst);
  return {
    subTotal,
    addOnTotal,
    gstAmount,
    total: gstAmount + subTotal + addOnTotal,
    originalSubTotal,
  };
};

const every = (obj) => {
  const arr = Object.keys(obj);
  for (let i = 0; i < arr.length; ++i) {
    if (!obj[arr[i]]) return ["cannot be empty", arr[i]];
    if (typeof obj[arr[i]] == "number" && obj[arr[i]] < 0)
      return ["cannot be negative", arr[i]];
  }

  return ["", ""];
};

export { calculateAmount, calculateAddOnAmount,calculateTotal, finalTotal, every };
