import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { finalTotal } from "../utils/calc";
import { useSelector, useDispatch } from "react-redux";
import { selectFormData, setFormData } from "../features/form/formSlice";

export default function DenseTable({ className }) {
  const formData = useSelector(selectFormData);
  const dispatch = useDispatch();
  const removeComponent = (id) => {
    // console.log(id);
    if (!id) return alert("Invalid Id");
    const componentList = formData.componentList.filter(
      (comp) => comp.id !== id
    );
    const { subTotal, gstAmount, total, originalSubTotal } = finalTotal(
      componentList,
      formData.gstPer,
      parseInt(formData.discount) || 0
    );
    dispatch(
      setFormData({
        ...formData,
        componentList,
        subTotal,
        gstAmount,
        total,
        originalSubTotal,
      })
    );
  };

  const columns = [
    { field: "id", headerName: "id", width: 90, hide: true },
    {
      field: "delete",
      headerName: "",
      renderCell: (params) => (
        <IconButton onClick={(e) => removeComponent(params.id)}>
          <CloseIcon />
        </IconButton>
      ),
      width: 60,
    },
    {
      field: "components",
      headerName: "Components",
      minWidth: 600,
      flex: 0.8,
    }, //DOne
    {
      field: "quantity",
      headerName: "Quantity",
      minWidth: 100,
      flex: 0.1,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 100,
      flex: 0.1,
    },
  ];
  // ABhi component select karke add kar
  return (
    <div className="mt-2" style={{ height: 400, maxWidth: "100%" }}>
      <DataGrid
        rows={formData.componentList}
        columns={columns}
        hideFooterPagination
        checkboxSelection={false}
        disableSelectionOnClick
      />
    </div>
  );
}

// Installation remove
// Material Remove
// Payment 100 % advanced

// Address - Bill Address and Delivery Address
