import { configureStore } from "@reduxjs/toolkit";
import formReducer from "../features/form/formSlice";
import userReducer from "../features/user/userSlice";

export const store = configureStore({
  reducer: {
    formData: formReducer,
    user: userReducer,
  },
});
