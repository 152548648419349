import { Button } from "@mui/material";
import React, { useState } from "react";
import Logo from "../assets/artemec.png";
import { addQuotation, updateQuotation } from "../util/db";

import { useSelector } from "react-redux";
import {
  selectFormData,
  selectIsEdit,
  selectIsView,
} from "../features/form/formSlice";

const Print = () => {
  // const { formData, isEdit } = useContext(DataContext);
  const formData = useSelector(selectFormData);

  const isEdit = useSelector(selectIsEdit);
  const isView = useSelector(selectIsView);
  const [loading, setLoading] = useState(false);

  const onSaveClick = async () => {
    setLoading(true);
    if (isEdit) {
      await updateQuotation({ ...formData, date: new Date(formData.date) });
    } else await addQuotation({ ...formData, date: new Date(formData.date) });
    setLoading(false);
  };

  return (
    <div className="w-full">
      <div className="flex justify-center mt-5">
        <div className="flex w-[900px] justify-between items-center ml-5">
          {!isView && (
            <div className="print-button space-x-4">
              <Button
                variant="contained"
                onClick={onSaveClick}
                disabled={loading}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={(e) => window.print()}
                disabled={loading}
              >
                Print
              </Button>
            </div>
          )}
          <img className="h-20" src={Logo} alt="artemec logo" loading="lazy" />
        </div>
      </div>
      <br></br>
      <div className="flex justify-center">
        <h3 className="border border-black mb-4 w-[900px] text-center text-lg font-semibold ml-5">
          {formData.formType}
        </h3>
      </div>
      <div className="flex  items-center justify-center ml-5">
        <table className="w-[900px] upper-left-table ">
          <tbody>
            <tr>
              <td className="font-semibold">Quote No:</td>
              <td width={396} colSpan={2}>
                {formData.qNo}
              </td>
              <td width={100} className="font-semibold">
                Date:
              </td>
              <td>{new Date(formData.date).toDateString()}</td>
            </tr>
            <tr>
              <td className="font-semibold">Project:</td>
              <td colSpan={4}>{formData.project}</td>
            </tr>
            <tr>
              <td className="font-semibold">Client:</td>
              <td colSpan={4}>{formData.client}</td>
            </tr>
            <tr>
              <td className="font-semibold">Address:</td>
              <td colSpan={2} width={392}>
                Delivery Address
              </td>
              <td colSpan={2} width={392}>
                Billing Address
              </td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={2} width={392}>
                {formData.bilAddr}
              </td>
              <td colSpan={2} width={392}>
                {formData.delAddr}
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td className="font-semibold">Reference:</td>
              <td colSpan={4}>{formData.ref}</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={2} width={392}>
                {formData.phone}
              </td>
              <td colSpan={2} width={392}>
                {formData.mail}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-8 ml-5">
        <table className="w-[900px]">
          <tbody>
            <tr>
              <th style={{ width: "8%" }}>Sr. No</th>
              <th style={{ width: "36%" }}>Component</th>
              <th style={{ width: "8%" }}>Qty</th>
              <th style={{ width: "8%" }}>Units</th>
              <th style={{ width: "25%" }}>Rate</th>
              <th style={{ width: "16%" }}>Amount</th>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            {formData.componentList.map((comp, i) => {
              if (!comp.addon) {
                return (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                    <td>{comp.components}</td>
                    <td style={{ textAlign: "right" }}>{comp.quantity}</td>
                    <td style={{ textAlign: "left" }}>
                      {comp.componentUnit || comp.addOnUnit}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {(comp.amount / comp.quantity).toFixed(2)}
                    </td>
                    <td style={{ textAlign: "right" }}>{comp.amount}</td>
                  </tr>
                );
              } else {
                return null;
              }
            })}

            <tr style={{ textAlign: "right" }}>
              {/* Yeh rowspan bc  */}
              <td colSpan="4" rowSpan="4"></td>
              <td className="font-semibold">Total :</td>
              <td className="font-semibold">{formData.originalSubTotal}</td>
            </tr>

            {/* now good */}
            {/* Big PP */}
            <tr style={{ textAlign: "right" }}>
              {/* <td colSpan="4" rowSpan="6"></td> */}
              <td>Discount (%) :</td>
              <td>{formData.discount}</td>
            </tr>
            <tr style={{ textAlign: "right" }}>
              {/* <td colSpan="4" rowSpan="6"></td> */}
              <td className="font-semibold">Sub-Total (A):</td>
              <td className="font-semibold">{formData.subTotal}</td>
            </tr>

            <tr>
              <td colSpan={2}></td>
            </tr>

            {formData.componentList.map((comp, i) => {
              if (comp.addon) {
                return (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                    <td>{comp.components}</td>
                    <td style={{ textAlign: "right" }}>{comp.quantity}</td>
                    <td style={{ textAlign: "left" }}>
                      {comp.componentUnit || comp.addOnUnit}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {(comp.amount / comp.quantity).toFixed(2)}
                    </td>
                    <td style={{ textAlign: "right" }}>{comp.amount}</td>
                  </tr>
                );
              } else {
                return null;
              }
            })}

            <tr style={{ textAlign: "right" }}>
              <td colSpan="4" rowSpan="6"></td>
              <td className="font-semibold">Add-On's Total (B):</td>
              <td className="font-semibold">{formData.addOnTotal}</td>
            </tr>

            <tr>
              <td colSpan={2}></td>
            </tr>

            {/* adding a new row here */}
            <tr style={{ textAlign: "right" }}>
              <td className="font-semibold">Grand Total (C = A + B):</td>
              <td className="font-semibold">
                {/* if either of them is null set that to 0 */}
                {(formData.subTotal ? formData.subTotal : 0) +
                  (formData.addOnTotal ? formData.addOnTotal : 0)}
              </td>
            </tr>

            <tr style={{ textAlign: "right" }}>
              <td>Add : CGST@9% :</td>
              <td>{formData.gstAmount / 2}</td>
            </tr>
            <tr style={{ textAlign: "right" }}>
              <td>Add : SGST@9% :</td>
              <td>{formData.gstAmount / 2}</td>
            </tr>
            <tr style={{ textAlign: "right" }}>
              <td className="font-semibold">Net Total :</td>
              <td className="font-semibold">{formData.total}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-5 ml-5 ">
        <strong>Terms &amp; Condition :-</strong>
        {/* <ul>
              <li>70% Advance alone with order.</li>
              <li>Balance on approval at the factory before dispatch.</li>
            </ul>
            <p>
              : Any damage to the product during transportation or on site will not
              be our responsibility.
              <br />: Transporation, Loading And Unloading, Packing charges extra as
              per actual.
            </p> */}
        {formData.note?.split("\n").map((data, i) => (
          <p key={i}> {data} </p>
        ))}
      </div>
    </div>
  );
};

export default Print;
